<template>
  <div class="row bg-white ">
    <div class="col-md-10 offset-2 mt-7 mb-5">
       <div class="row mt-2">
          <div class="col-md-5 row p-0">
            <div class="col-12 bg-transparent w-100">
              <img
                :src="primary_image"
                class="img-fluid h-auto col-12 "
                alt
                srcset
              />
              <div v-if="product.galleries" class="w-100 col-12 mt-2">
                <img
                  v-for="pic in product.galleries"
                  :key="pic.imageUrl"
                  :src="root_img_url+ pic.file"
                  class="avatar avatar-lg border rounded mr-3 hover-cursor"
                  alt
                  @click="primary_image = root_img_url+pic.file"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-2 ml-4">
            <h4 class="h1 text-default">{{ product.name }}</h4>
            <h2 class="h4 mt-2 text-default">
                <span class="text-darker text-md">
                  <em class="fas mr-1 fa-tag"></em>
                  <span v-if="product.discount"
                    >{{ $num_format(get_price) }} {{product.currency}}</span
                  >
                  <span :class="product.discount ? 'text-cross ml-2 text-muted' : ''"
                    >{{ $num_format(dynamic_price) }} {{product.currency}}</span
                  >
                  <span
                    v-if="product.discount"
                    class="badge-warning badge badge-pill ml-2"
                    >{{ product.discount }}% off</span
                  >
                </span>
             </h2> 
             <star-rating
              v-if="product.ratings <= 5 && product.ratings > 0"
              :rating="product.ratings"
            ></star-rating>
             <div class="row">
                <div class="col-md-12 mt-5">
                  <h2 class="display-4 mb-3" v-if="$i18n.locale=='kin'" >Ubusobanuro Bwigicuruzwa</h2>
                  <h2 class="display-4 mb-3" v-else >Product Description</h2>
                  <p class="text-black-50" v-html="product.description"></p>
                </div>
             </div>

          </div>
       </div>
       <hr class="m-2">
       <div class="row mb-3 " >
        <div class="col-md-12">
          <router-link
              class=""
              v-if="product.entity"
              :to="{
                      name: 'Company Profile',
                      params: {
                        slug: product.entity?.slug,
                      },
                    }"
          >
            <div class="text-default h3">Business contacts</div>
            <div class="text-muted  justify-content-start" >
              <span>{{entity?entity.name:""}} &nbsp;</span>
              <span class="fa fa-phone-square"> {{entity.phone}}</span>
            </div>

          </router-link>
        </div>
       </div>
       <div class="row">
         <div class="col-md-8" v-if="product.entity">
           <Slider :slideTitle="'Products from ' + product.entity.name+' Seller'" >
             <div class="row">
               <div
                   class="col-md-3"
                   v-for="prod in products"
                   :key="prod.uuid"
               >
                 <product-card :product="prod"  btn_size="small">
                 </product-card>
               </div>
             </div>
           </Slider>
         </div>
         <div class="col-md-4" v-if="product.uuid">
           <product-reviews
               :productUuid="product.uuid"
               :productRate="product.ratings"
               :owner="own_product"
               :product="product"
           ></product-reviews>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { alert } from "@/utils/alertUtils";
import StarRating from "../components/StarRating";
import Slider from "../components/Slider";
import ProductCard from "../components/ProductCard.vue";
import Reviews from "../components/ReviewsComponent";
export default {
  name: "Product details",
  props: ["slug"],
  components: {
    Slider,
    "product-card": ProductCard,
    "star-rating": StarRating,
    "product-reviews": Reviews,
  },
  computed: {
    get_price() {
      if (this.product.discount) {
        return (
          this.dynamic_price -
          this.dynamic_price * (this.product.discount / 100)
        );
      }
      return this.dynamic_price;
    },
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
      root_img_url:process.env.VUE_APP_IMG_URL,
      quantity: 1,
      avatar_classes: "",
      primary_image: "",
      dynamic_price: "",
      product: {
        options: [],
        choices: [],
        ranges: [],
      },
      products: [],
      active_option: {
        choices: [],
        ranges: [],
      },
      active_choice: {},
      errors: {
        product_option_uuid: [],
        product_choice_uuid: [],
        quantity: [],
        comment: [],
      },
      comment: "",
      loading: false,
      entity: null,
      own_product:false
    };
  },
  watch: {
    slug: function () {
      this.fetch_product();
      this.active_option = {
        choices: [],
        ranges: [],
      };
      this.comment = "";
      this.active_choice = {};
      this.quantity = 1;
    },
    quantity: function () {
      this.get_price_by_range();
    },
    product_name: function () {
      this.fetch_product();
      this.active_option = {
        choices: [],
        ranges: [],
      };
      this.comment = "";
      this.active_choice = {};
      this.quantity = 1;
    },
  },
  methods: {
    check_own_product:function(){
      if(this.$store.state.auth.userProfile.entity && this.$store.state.auth.userProfile ){
        if (this.product.entity.uuid===this.$store.state.auth.userProfile.entity.uuid){
          this.own_product=true
        }else{
          this.own_product=false
        }
      }else{
        return false
      }
    },

    fetch_product: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/detail/${this.slug}`
        )
        .then((res) => {

          this.product = res.data.product;
          document.title= "BCM-"+this.product.name
          this.dynamic_price = this.product.price;
          this.primary_image =this.root_img_url+ this.product.primeImageUrl;
          this.entity = this.product.entity;
          this.fetch_products_by_entity();
          this.check_own_product()
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submit: function () {
      this.loading = true;
      let post_data = {
        product_uuid: this.product.uuid,
        quantity: this.quantity,
        comment: this.comment,
      };
      if (this.active_option.uuid)
        post_data["product_option_uuid"] = this.active_option.uuid;
      if (this.active_choice.uuid)
        post_data["product_choice_uuid"] = this.active_choice.uuid;
      
      if(this.comment.length>0){
            axios
              .post(
                `${process.env.VUE_APP_BACKEND_URL}/api/orders/inquiry/products`,
                post_data,
                {
                  headers: {
                    Authorization: `Bearer ${this.$store.getters.getToken}`,
                  },
                }
              )
              .then(() => {
                alert.success(
                  "Order is sent to the seller, They will be in touch soon."
                );
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                if (err.response) {
                  if (err.response.status === 400) {
                    this.errors = err.response.data;
                  } else {
                    alert.error("Error occured please try again later!");
                  }
                }
            });
      }else{
         this.loading = false;
        alert.error(
                  "Order description is required"
                );
      }
      
    },

    fetch_products_by_entity: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.product.entity.uuid}/products/`
        )
        .then((res) => {
          this.products = res.data
            .filter((pr) =>  pr.uuid !== this.product.uuid)
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {},
  created() {
    this.fetch_product();
  },
};
</script>

<style scoped>
.active {
  border: 2px solid #f1764ba1;
}
.pad-option {
  /* border-radius: 10%; */
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}
.text-cross {
  text-decoration: line-through;
}
</style>