import { store } from "../store";
class Helpers{
    static async gaveRatings(id,type) {
        if (type === "product") {
            let products_data=store.state.ratings_data.products
            for(let i=0;i<products_data.length;i++){
                if(products_data[i]===id){
                    return true
                }
            }

        } else if (type === 'entity') {
            let entities_data=store.state.ratings_data.entities
            for(let j=0;j<entities_data.length;j++){
                if(entities_data[j]===id){

                    return true
                }
            }
        }else{
            return false
        }

    }

    static async saveRating(id,type){
        let data=store.state.ratings_data
        if(type==="product"){
            data.products.push(id)
            store.commit("update_ratings_data",data)
        }else if(type==="entity"){
            data.entities.push(id)
            store.commit("update_ratings_data",data)
        }
    }



}

export default Helpers