<template>
  <div>
    <div :class="$displayManager.isMobile()? 'star-rating font-size-mobile-display':'star-rating normal-font-size-display'">
      <span v-for="(n, index) in 5" :key="index">&star;</span>
      <div class="star-rating__current" :style="{ 'width': getRating + '%' }">
        <span v-for="(n, index) in 5" :key="index">&starf;</span>
      </div>
    </div>
    <span v-if="showCount" class="ml-3 text-muted text-sm">{{ Math.round(getRating * 5 / 100) }}</span>
  </div>
</template>
<script>
export default {
  name: 'rating',
  props: {
    rating: {
      default: 0,
      required: true,
    },
    showCount: {
      default: true,
      required: false
    }

  },
  data() {
    return {

    }
  },
  computed: {
    getRating: function () {
      if (this.rating > 0) {
        return (this.rating / 5) * 100
      } else {
        return 0
      }

    }
  },

}
</script>
<style lang="css" scoped>
.star-rating {
  display: inline-block;

  color: yellowgreen;
  position: relative;
}

.star-rating .star-rating__current {
  position: absolute;
  top: 0;
  overflow: hidden;
  white-space: nowrap;

}

.font-size-mobile-display {
  font-size: 2vmax;
}
.normal-font-size-display {
  font-size: 1.1vmax;
}
</style>