import Helpers from "@/utils/helpers";

let ratingMixin={
    data(){
        return{
            can_give_rating:false,
            can_give_entity_rating:false,
            chooseTheRating:false
        }
    },
    methods:{
        gave_product_rating:function () {
            Helpers.gaveRatings(this.productUuid,'product').then((res)=>{
                this.can_give_rating=!res
            })
        },
        gave_entity_rating:function (){
            Helpers.gaveRatings(this.entityUuid,'entity').then((res)=>{
                this.can_give_entity_rating=!res
            })
        },
        ratehandle:function(evt){

            if(!this.chooseTheRating){
                if(evt==0){
                    document.getElementById("star-1").classList.add("checked")
                    this.removechecked([1,2,3,4,5])
                }else if(evt==1){
                    document.getElementById("star-1").classList.add("checked")
                    this.removechecked([2,3,4,5])
                }else if(evt==2){
                    document.getElementById("star-1").classList.add("checked")
                    document.getElementById("star-2").classList.add("checked")
                    this.removechecked([3,4,5])
                }else if(evt==3){
                    document.getElementById("star-1").classList.add("checked")
                    document.getElementById("star-2").classList.add("checked")
                    document.getElementById("star-3").classList.add("checked")
                    this.removechecked([4,5])
                }else if(evt==4){
                    document.getElementById("star-1").classList.add("checked")
                    document.getElementById("star-2").classList.add("checked")
                    document.getElementById("star-3").classList.add("checked")
                    document.getElementById("star-4").classList.add("checked")
                    this.removechecked([5])
                }else if(evt==5){
                    document.getElementById("star-1").classList.add("checked")
                    document.getElementById("star-2").classList.add("checked")
                    document.getElementById("star-3").classList.add("checked")
                    document.getElementById("star-4").classList.add("checked")
                    document.getElementById("star-5").classList.add("checked")
                }
            }

        },
        removechecked:function(values){
            for(var i=0;i<values.length;i++){
                document.getElementById("star-"+values[i]).classList.remove("checked")
            }
        },

        resetRating:function(value){
            for(let i=1;i<=value;i++){
                document.getElementById("star-"+i).classList.remove("checked")
            }
        },

        setStars:function (evt) {
           this.resetRating(5);
            this.post_data.star_rating=evt;
            for(let i=1;i<=evt;i++){
                document.getElementById(`star-${i}`).classList.add("checked")
            }
            this.chooseTheRating=true;
        }

    },
    created() {
        this.gave_product_rating()
        this.gave_entity_rating()
    }
}
export default ratingMixin